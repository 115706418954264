import React from 'react'
import {Grid, makeStyles, Slide, Typography, Zoom } from '@material-ui/core'
import useUsView from './useOurClientsView';
import logo from '../../assets/logo-althar-blanco.png'
import prueba from '../../assets/punch.png'
import clients from '../../assets/PNG/PNG/WEBnegro_70.png'

const OurClientsView = ({language}) =>{

  const classes = useStyles()
  const content = contentData[language]
  const {scroll_position, actions, modals} = useUsView({})

  const items = [
    prueba,
    prueba,
    prueba,
    prueba,
    prueba,
    prueba,
    prueba,
    prueba
  ]

  return(
    <div className={classes.root}>  
      <Slide direction='right' in={true}  timeout={1200}>
        <div className={classes.title_container}>
            <img src={logo} alt='' className={classes.logo}/>
            <Typography variant='h2' className={classes.title} >OUR CLIENTS</Typography>
        </div>
      </Slide> 
      {/* <div className={classes.values_container}>
        <Grid container spacing={5} justify='center' >
            {items.map((el, ind) => {
              return(
                <Grid item key={ind.toString()}  >
                  <Zoom in={true} timeout={1000 + ind*500} >
                    <div className={classes.item_container}>
                      <img alt='' src={el} className={classes.item} />
                    </div>
                  </Zoom>
                </Grid>
              )
            })}
        </Grid>
      </div> */}
      <Slide direction='left' in={true}  timeout={2400}>
          <div className={classes.image_container}>
              <img alt='' src={clients} className={classes.image} />
          </div>
      </Slide>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  root:{
    position:'relative',
    padding:'100px 40px',
    background:'black',
    height:'100vh'
  },
  title:{
    color:theme.palette.primary.main,
    marginLeft:24,
    [theme.breakpoints.down('sm')]: {
      marginLeft:0
    },
  },
  title_container:{
    position:'relative',
    display:'flex',
    alignItems:'end',
    [theme.breakpoints.down('sm')]: {
      display:'inline'
    },
  },
  values_container:{
    //marginLeft:40,
    marginTop:40,
    width:1100,
    margin:'auto',
    [theme.breakpoints.down('sm')]: {
      width:'100%'
    },
  },
  logo:{
    width:400,
    paddingBottom:12,
    [theme.breakpoints.down('sm')]: {
      width:300
    },
  },
  item_container:{
    width:80,
    height:80,
    padding:30,
    borderRadius:'50%',
    background:'white'
  },
  item:{
    width:'100%',
    height:'100%',
    objectFit:'contain'
  },
  image_container:{
    position:'absolute',
    top:0,
    left:0,
    [theme.breakpoints.only('md')]: {
      top:100
    },
    [theme.breakpoints.only('sm')]: {
      top:200
    },
    [theme.breakpoints.only('xs')]: {
      top:400
    },
  },
  image:{
    width:'100%',
    height:'100%',
    objectFit:'contain'
  }
}))

const contentData = {
  english:{
    sectionA:{
      title:'Mission',
      data:'We are transforming health with technology, data, design and frenzy for life.',
      timeout:2400
    },
    sectionB:{
      title:'Courage',
      data:'Enthusiasm, true conviction and fearless believe lead our standards way beyond expectations.',
      timeout:3000
    },
    sectionC:{
      title:'Character',
      data:'Mixing our talents and moral quality; We have the right motives to achieve greater results.',
      timeout:3600
    },
    sectionD:{
      title:'Integrity',
      data:'We always do the right things, building up strong and lasting working relationships.',
      timeout:4200
    },
    sectionE:{
      title:'Innovation',
      data:'We strive toward to create and design.',
      timeout:4800
    }
  }
}

export default OurClientsView