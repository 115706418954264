import React from 'react'
import { makeStyles, withWidth, Fade, Grid } from '@material-ui/core';
import logo from '../../../../../assets/logo_althar.png'
import { grey } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    //position:'relative',
    zIndex:99,
    width:'100%',
    height:'100vh',
   
  },
  container:{
    //width:1400,
    margin:'100px auto',
    paddingLeft:100,
    //padding:100,
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    //background:'red',
    [theme.breakpoints.only('lg')]: {
      //width:1100,
      //margin:'100px auto',
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px',
      margin:'80px auto',
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 32px',
      marginTop:200
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 32px',
      marginTop:220,
      //height:260,
      //zIndex:200
      //background:'red',
    },
  },
  title:{
    fontSize:72,
    fontWeight:700,
    [theme.breakpoints.only('lg')]: {
      fontSize:62,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:38,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:32
    },
  },
  subtitle:{
    marginTop:32,
    fontSize:28,
    fontWeight:400,
    width:500,
    //color: grey[700],
    //background:'red',
    [theme.breakpoints.only('lg')]: {
      width:400,
      fontSize:24,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      fontSize:22,
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      marginTop:15,
      fontSize:14,
    },
  },
  logo:{
    width:420,
    [theme.breakpoints.only('lg')]: {
      width:380,
    },
    [theme.breakpoints.only('md')]: {
      width:340,
    },
    [theme.breakpoints.only('sm')]: {
      width:300,
      marginLeft:'auto'
    },
    [theme.breakpoints.only('xs')]: {
      width:220,
    },
  },
  header:{
    //background:'red',
    [theme.breakpoints.only('sm')]: {
      marginTop:-120
    },
    [theme.breakpoints.only('xs')]: {
      marginTop:-130,
    },
  },
  rectangle:{
    width:260,
    height:80,
    background:theme.palette.primary.main,
    marginLeft:200,
    marginTop:60
    //position:'absolute',
    //bottom:0
  }
}))

const ScreenB = props => {

  const classes = useStyles()
  const {scroll, range, offset, content, color} = props

  const showContent = scroll >= range.min + range.offset + offset && scroll < range.max + range.offset + offset
  
  return(
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Fade in={showContent} timeout={750}>
              <div>
                <Grid container alignItems='baseline' spacing={2} className={classes.header}>
                  <Grid item><img src={logo} alt='' className={classes.logo}/></Grid>
                  <Grid item xs><div className={classes.title}>{content.title}</div></Grid>
                </Grid>
              </div>
            </Fade>
            <Grid item xs={12}>
              <Fade in={showContent} timeout={750}>
                <div className={classes.subtitle}>{content.data}</div>
              </Fade>
            </Grid>
          </Grid>
        </Grid>   
        <Fade in={showContent} timeout={750}>
          <div className={classes.rectangle}></div>
        </Fade>    
      </div>
    </div>
  )
}

export default withWidth()(ScreenB)