import React from 'react'
import { withStyles, Fade, Slide, Typography, Zoom } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import IconMask from './components/IconMask';
import LogoGeneralMask from './components/LogoGeneralMask';
import Manifiesto from './components/Manifiesto';
import manifiestB from '../../../../assets/carouselB.jpg'
import manifiestC from '../../../../assets/carouselC.jpg'
import manifiestD from '../../../../assets/carouselD.jpg'


//const totalHeight = 4500
const totalHeight = 7000

const range_text_a = {min:0, max:800, offset:0}
const range_text_b = {min:0, max:800, offset:800}
const range_primary_icon = {min:0, max:1600, offset:0}
const range_overlay = {min:0, max:1600, offset:1600}

const SectionA = ({scroll_position, classes, actions}) => {


    let text_a_fade = actions.onFadeText(range_text_a)
    let text_b_fade = actions.onFadeText(range_text_b)

    let overlay_fade = actions.onFadeText(range_overlay)
    let overlay_scale = actions.onScaleSVG(range_overlay)

    console.log(scroll_position)

    return(
      <div className={classes.root}> 
        <div className={classes.emptySpace}/>
        <div className={classes.container} style={{overflow:'hidden', }}>
          <div style={{overflowX:'hidden'}}>        
              <div className={classes.txtWrapper} style={{color:fade('#000', text_a_fade)}}>
                <Slide direction='right' in={actions.onItemValid(range_text_a)} timeout={1000}>
                  <div>
                    <div align='left' className={classes.letter}>Discover.</div>
                    <div align='left' className={classes.letter}>Design.</div>
                    <div align='left' className={classes.letter}>Delight.</div>
                  </div>
                </Slide>              
              </div>           
            <Fade in={actions.onItemValid(range_text_b)} timeout={500}>
              <div className={classes.textB_wrapper}>
                <div className={classes.textB_container} style={{color:fade('#000', text_b_fade)}}>
                  A life that’s full of
                  Health, Happiness 
                  and Hope.
                </div>
              </div>
            </Fade>
            <IconMask visibility={actions.onItemValid(range_primary_icon)}/>
          </div>   
          {scroll_position > range_text_b.max+range_text_b.offset && scroll_position < range_text_b.max+range_text_b.offset + 2100 ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Manifiesto />
              <div className={classes.overlay} style={{background:fade("#C83D84",overlay_fade),}}/>
              {scroll_position < 3100 ? <LogoGeneralMask scale={overlay_scale}/> : null }             
            </div>
          ) : null}
          {scroll_position > range_text_b.max+range_text_b.offset + 2050 && scroll_position < range_text_b.max+range_text_b.offset + 2800 ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Fade in={scroll_position > range_text_b.max+range_text_b.offset + 2050} timeout={2600}>
                <img src={manifiestB} className={classes.image} />     
              </Fade>      
            </div>
          ) : null}   
          {scroll_position > range_text_b.max+range_text_b.offset + 2750 && scroll_position < range_text_b.max+range_text_b.offset + 3500 ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Zoom in={scroll_position > range_text_b.max+range_text_b.offset + 2200} timeout={2000}  >
                <img src={manifiestC} className={classes.image} />  
              </Zoom>           
            </div>
          ) : null}
          {scroll_position > range_text_b.max+range_text_b.offset + 3450  ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Slide in={scroll_position > range_text_b.max+range_text_b.offset + 3500} timeout={2000} direction="up">
                <img src={manifiestD} className={classes.image} /> 
              </Slide>            
            </div>
          ) : null}
        </div>   
      </div>
    )
}



const styles = theme => ({
  root:{
    height:`${totalHeight}px`,
    position:'relative',
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing',
  },
  container:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
  },
  imageContainer:{
    position:'relative',
    width:'100%',
    height:'100vh',
    //visibility:'hidden',
    background:'transparent',

  },
  image:{
    width:'100%',
    height:'100%',
    [theme.breakpoints.down('md')]: {
      objectFit:'contain',
      height:'100%'
    },
  },
  letter:{
    fontFamily:'Avenir',
    fontSize:140,
    fontWeight:600,
    padding:0,
    margin:0,
    [theme.breakpoints.only('lg')]: {
      fontSize:120,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:100,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:80,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:50,
    },
  },
  txtWrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-70%, -50%)',
    zIndex:10
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:10
  },
  textB_container:{ 
    width:1200,
    fontFamily:'Avenir',
    fontSize:120,
    fontWeight:600,
    [theme.breakpoints.only('lg')]: {
      width:1000,
      fontSize:100,
    },
    [theme.breakpoints.only('md')]: {
      width:800,
      fontSize:80,
    },
    [theme.breakpoints.only('sm')]: {
      width:500,
      fontSize:60,
    },
    [theme.breakpoints.only('xs')]: {
      width:300,
      fontSize:50,
    },
  },
  overlay:{
     
    width:'100%', 
    height:'100%',
    top:0,
    left:0,
    position:'absolute',
    zIndex:1
  }
})

export default withStyles(styles)(SectionA)