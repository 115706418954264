import React from 'react'
import { Slide, withStyles } from '@material-ui/core';
import ScreenA from './components/ScreenA';
import logo from '../../../../assets/logo-althar-blanco.png'


const totalHeight = 2000
const range_first_screen = {min:0, max:1000, offset:0}


const SectionE = ({classes, language, scroll_position, offset, actions, onChangeModal}) => {

  let fade_first_screen = actions.onFadeText(range_first_screen)

  return( 
    <div className={classes.root} id={4} > 
      <div className={classes.emptySpace} />
      <div className={classes.white_container}></div>
      <div className={classes.black_container}>
        <Slide direction='right' in={scroll_position > 100 + offset}  timeout={1200}>
          <div className={classes.logo_container}>
              <img src={logo} alt='' className={classes.logo}/>
          </div>
        </Slide>
      </div>
      <div className={classes.container} style={{overflow:'hidden'}}>
        <div className={classes.subcontainer} style={{overflow:'hidden'}}>
          <ScreenA language={language} scroll={scroll_position} range={range_first_screen} offset={offset} fade_in={fade_first_screen}
           onChangeModal={onChangeModal}/>              
        </div>
      </div>
    </div>
  )
  
}


const styles = theme => ({
  generalContainer:{
    width:1400,
    margin:'auto',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 50px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 32px'
    },
  },
  root:{
    height:`${totalHeight}px`,
    position:'relative',
    background:'black'
  },
  rootB:{
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%)',
  },
  rootC:{
    background:'transparent'
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing'
  },
  container:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
  },
  subcontainer:{
    
  },
  containerB:{
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%)',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 2500,
    }),
  },
  imageContainer:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    background:'transparent',

  },
  image:{
    width:'100%',
  },
  letter:{
    fontFamily:'Avenir',
    fontSize:140,
    fontWeight:600,
    padding:0,
    margin:0,
  },
  txtWrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-70%, -50%)',
    zIndex:10
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:10
  },
  textB_container:{ 
    width:1200,
    fontFamily:'Avenir',
    fontSize:120,
    fontWeight:600,
  },
  overlay:{
     
    width:'100%', 
    height:'100%',
    top:0,
    left:0,
    position:'absolute',
    zIndex:1
  },
  screenWrapperA:{
    overflowX:'hidden', 
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%);',
  },

  white_container:{
    height:'50vh',
    background:'white'
  },
  black_container:{
    height:'50vh',
    background:'black',
    position:'relative'
  },
  logo:{
    width:400
  },
  logo_container:{
    position:'absolute',
    bottom:0,
    left:40,
    zIndex:1,
    //background:'red',
    //width:'100%'
  }
  
})

export default withStyles(styles)(SectionE)