import React, { useEffect, useState } from 'react';
import { withStyles, Fab, Icon, Grid, Fade } from '@material-ui/core';
import cx from 'classnames'
import logo from '../../../assets/alther_logo_w.png'
import { makeStyles } from '@material-ui/styles';

const menuItems = [
  {id:1, label:'Us', url:'/us'},
  {id:2, label:'Work', url:'/work'},
  {id:3, label:'Services', url:'/', section:'services'},
  {id:4, label:'Solutions', url:'/', section:'solutions'},
  {id:5, label:'Our clients', url:'/our-clients'},
  {id:6, label:'Newsletters', url:'/newsletters'},
  {id:7, label:'Contact', url:'/contact'},
  
]

const NavBar = ({classes, history}) => {


  const [open, setOpen] = useState(false)

  const [section_id, setSectionId] = useState(null)

  const onChange = () => {
    //setOpen(prevState => {return {open:!prevState.open}})
    setOpen(prevState => !prevState)
  }

  const onSelectItem = (data) => {
    
    if(data.section){
      //history.push('/')
      setSectionId(data.id)
      /* var ele = window.document.getElementById(data.id)
      console.log(ele) */
      /* if(ele){
        console.log(ele.offsetLeft, ele.offsetTop)
        window.scrollTo(ele.offsetLeft,ele.offsetTop)
      } */
    }
    const {url} = data
    history.push(url)
    setOpen(false)
  }

  const onGoToMainView = () => {
    history.push('/')
    setOpen(false)
  }

  useEffect(() => {
    if(section_id){
      console.log(section_id)
      //console.log(ele.offsetLeft, ele.offsetTop)
      var ele = window.document.getElementById(section_id)
      if(ele){
        console.log(ele.offsetLeft, ele.offsetTop)
        window.scrollTo(ele.offsetLeft,ele.offsetTop)
      }
    }
  }, [section_id])


  return(
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={cx({
          [classes.circle]:true,
          [classes.circleOpen]:open
        })}></div>
        <nav className={cx({
          [classes.nav]:true,
          [classes.navOpen]:open
        })}>
          <Fade in={open} timeout={2500}>
            <div className={classes.navContainer}>
              <Grid container justify='flex-end'>
                <Grid item>
                  <div style={{cursor:'pointer'}} onClick={onGoToMainView}>
                    <img src={logo} alt='' className={classes.image}/>
                  </div>
                </Grid>
                <Grid item xs/>
                <Grid item>
                  <div className={classes.menu}>
                    <Grid container >
                      {menuItems.map((item,key)=>{
                        return(
                          <Grid item xs={12} key={key.toString()}>
                            <MenuItem data={item} onClick={()=>onSelectItem(item)} />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fade>   
        </nav>
        <Grid container>
          <Grid item xs/>
          <Grid item>
            <Fab className={cx({
              [classes.fab]:true,
              [classes.fabOpen]:open,
            })} onClick={onChange}><Icon>{open ? 'close' : 'menu'}</Icon></Fab>
          </Grid>
        </Grid>
      </div>
    </div>
  )
  
}

const styles = theme => ({
  root:{
    position:'fixed',
    width:'100%',
    boxSizing:'border-box',
    zIndex:1,
  },
  container:{
    position:'relative',
    margin:'40px 40px'
  },
  navContainer:{
    width:1400,
    margin:'100px auto',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1000,
    margin:'100px auto',
    },
    [theme.breakpoints.only('md')]: {
      width:800,
      margin:'100px auto',
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      margin:'100px auto',
    },
  },
  menu:{
    width:150,
    
    [theme.breakpoints.only('xs')]: {
      //background:'red',
      marginRight:40,
      marginTop:20
    },
  },
  image:{
    width:400
  },
  nav:{
    position:'fixed',
    top:0,
    left:0,
    width:'100vw',
    height:'100vh',
    zIndex:1,
    transform:'scale(0)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeOut,
      duration: 200,
    }),
  },
  navOpen:{
    transform:'scale(1)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easiIn,
      duration: 200,
    }),
  },
  circle:{
    position:'absolute',
    background:theme.palette.primary.main,
    top:30,
    right:30,
    width:'265vw',
    height:'265vw',
    transform: 'translate(50%, -50%) scale(0.0017)',
    borderRadius:'100%',
    transition:'transform 0.5s ease 0s',
    [theme.breakpoints.only('xs')]: {
      //width:'100%',
      //height:'100%'
    },
  },
  circleOpen:{
    transform: 'translate(50%, -50%) scale(1)',
    transition:'transform 0.5s ease 0s'
  },
  fab:{
    background:theme.palette.primary.main,
    color:'white',
    zIndex:1,
    '&:hover':{
      background:theme.palette.primary.main,
    },
    transition: theme.transitions.create(['background', 'color'], {
      easing: theme.transitions.easing.easeOut,
      duration: 350,
    }),
  },
  fabOpen:{
    background:'white',
    color:theme.palette.primary.main,
    '&:hover':{
      background:'white',
    },
    transition: theme.transitions.create(['background', 'color'], {
      easing: theme.transitions.easing.easeOut,
      duration: 350,
    }),
  }
})

export default withStyles(styles)(NavBar)

const useStyles = makeStyles(theme => ({
  root:{
    color:'rgba(255,255,255,0.8)',
    fontWeight:400,
    fontSize:32,
    //background:'red',
    '&:hover':{
      cursor:'pointer',
      color:'rgba(255,255,255,1)',
      fontWeight:600,
      fontSize:32,
      transition: theme.transitions.create(['color','font-weight', 'font-size'], {
        easing: theme.transitions.easing.easiIn,
        duration: 350,
      }),
    },
    transition: theme.transitions.create(['color','font-weight', 'font-size'], {
      easing: theme.transitions.easing.easiIn,
      duration: 350,
    }),
    [theme.breakpoints.only('xs')]: {
      textAlign:'end'
    },
  }
}))

const MenuItem = props => {

  const {data, onClick} = props
  const classes = useStyles()
  return(
    <div className={classes.root} onClick={onClick} >
      <div>{data.label}</div>
    </div>
  )
}