import React from 'react'
import {Grid, makeStyles, Slide, Typography } from '@material-ui/core'
import useUsView from './useUsView';
import logo from '../../assets/logo_althar.png'

const UsView = ({language}) =>{

  const classes = useStyles()
  const content = contentData[language]
  const {scroll_position, actions, modals} = useUsView({})

  const items = Object.values(content)
  //console.log(items)

  return(
    <div className={classes.root}>  
      <Slide direction='left' in={true}  timeout={1200}>
        <div className={classes.title_container}>
            <img src={logo} alt='' className={classes.logo}/>
            <Typography variant='h1' className={classes.title} >us</Typography>
        </div>
      </Slide> 
      <div className={classes.values_container}>
        <Grid container spacing={5}>
            {items.map((el, ind) => {
              return(
                <Grid item key={ind.toString()} xs={12} >
                  <Slide direction='left' in={true} timeout={el.timeout}>
                    <div>
                      <Typography variant='h3'>{el.title}</Typography>
                      <Typography variant='h6' style={{maxWidth:500}} >{el.data}</Typography>
                    </div>
                  </Slide>
                </Grid>
              )
            })}
        </Grid>
      </div>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  root:{
    position:'relative',
    padding:40
    
  },
  title:{
    marginLeft:24,
    [theme.breakpoints.down('sm')]: {
      marginLeft:0
    },
  },
  title_container:{
    position:'relative',
    display:'flex',
    alignItems:'end',
    [theme.breakpoints.down('sm')]: {
      display:'inline'
    },
  },
  values_container:{
    //marginLeft:40,
    marginTop:40
  },
  logo:{
    width:400,
    paddingBottom:20,
    [theme.breakpoints.down('sm')]: {
      width:300,
      paddingBottom:0
    },
  }
}))

const contentData = {
  english:{
    sectionA:{
      title:'Mission',
      data:'We are transforming health with technology, data, design and frenzy for life.',
      timeout:2400
    },
    sectionB:{
      title:'Courage',
      data:'Enthusiasm, true conviction and fearless believe lead our standards way beyond expectations.',
      timeout:3000
    },
    sectionC:{
      title:'Character',
      data:'Mixing our talents and moral quality; We have the right motives to achieve greater results.',
      timeout:3600
    },
    sectionD:{
      title:'Integrity',
      data:'We always do the right things, building up strong and lasting working relationships.',
      timeout:4200
    },
    sectionE:{
      title:'Innovation',
      data:'We strive toward to create and design.',
      timeout:4800
    }
  }
}

export default UsView