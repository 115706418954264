  import React from 'react'
  import { makeStyles, Fade, withWidth, Grid } from '@material-ui/core';
  import cx from 'classnames'
  import althar from '../../../../../assets/a-73.svg'

  const vector_size = {
    'xs': {width:625,height:197, offset_x:260, offset_y:-10, scale:0.2},
    'sm': {width:625,height:197, offset_x:150, offset_y:-10, scale:0.5},
    'md': {width:625,height:197, offset_x:142, offset_y:-16, scale:0.7},
    'lg': {width:625,height:197, offset_x:106, offset_y:-16, scale:0.85},
    'xl': {width:625,height:197, offset_x:70, offset_y:-16, scale:1},
  }

  const vector_size_B = {
    'xs': {width:625,height:197, offset_x:310, offset_y:0, scale:0.5},
    'sm': {width:625,height:197, offset_x:200, offset_y:0, scale:0.7},
    'md': {width:625,height:197, offset_x:9, offset_y:70, scale:1.25},
    'lg': {width:625,height:197, offset_x:-52, offset_y:70, scale:1.5},
    'xl': {width:625,height:197, offset_x:-174, offset_y:80, scale:2},
  }

  const vector_size_C = {
    'xs': {width:625,height:197, offset_x:-900, offset_y:200, scale:5},
    'sm': {width:625,height:197, offset_x:-1400, offset_y:200, scale:7},
    'md': {width:625,height:197, offset_x:-2355, offset_y:190, scale:11},
    'lg': {width:625,height:197, offset_x:-2355, offset_y:188, scale:11},
    'xl': {width:625,height:197, offset_x:-4053, offset_y:187, scale:18},
  }


  const useStyles = makeStyles(theme => ({
    svgImage:{
      position:'absolute',
      width:'625px',//'625px',
      height:'197px',//'197px',
      top:'50%',
      left:'50%',
      zIndex:1,
      transition: theme.transitions.create(['transform','width','height'], {
        easing: theme.transitions.easing.easeIn,
        duration: 1000,
      }),
      //[theme.breakpoints.down('lg')]: {
      //  transform:'translate(calc(-50% + 22%), calc(-50% - 40%)) scale(0.85)',
      //},
      //[theme.breakpoints.down('md')]: {
      //  transform:'translate(calc(-50% + 10%), calc(-50% - 20%)) scale(0.60)',
      //},
    },
  }))

  const AltharLogo =  props => {

    const {range, scroll, max_visibility, width} = props
    const classes = useStyles()

    let logo_size = vector_size[width]

    if(scroll >= range.max+range.offset && scroll < range.max+range.offset+range.offset2){
      logo_size = vector_size_C[width]
    }else if(scroll >= range.max+range.offset+range.offset2){
      logo_size = vector_size_C[width]
    }

    let logo_offset_x = 0 + logo_size.offset_x
    //let logo_offset_y = (logo_size.height*logo_size.scale)/2 - (logo_size.offset_y*logo_size.scale)
    let logo_offset_y = (logo_size.height*logo_size.scale)/2 - (logo_size.offset_y*logo_size.scale)
    //console.log(logo_offset_y)
    let logo_scale = logo_size.scale

    

    return(
    
        <div className={cx({
          [classes.svgImage]:true,
          [classes.svgTransition]:scroll >= range.max+range.offset,
          [classes.svgTransition2]:scroll >= range.max+range.offset+range.offset2,
        })} 
          style={{
            visibility: scroll < max_visibility ? 'visible' : 'hidden',
            transform:`translate(calc(0% - ${logo_offset_x}px), calc(-50% - ${logo_offset_y}px)) scale(${logo_scale})`,
          }}
          >
            <Fade direction='right' in={scroll >= (range.min + range.offset)} timeout={2500}>
              <div style={{marginLeft:16}}>
                  <SVGAlthar />
              </div>
            </Fade>
          
        </div>
      
      
    )
  }

  export default withWidth()(AltharLogo)


  const SVGAlthar = ({
    className="" 
  }) => {
    return(
      <Grid container alignItems='flex-end'>
        <Grid item>
          <div style={{height:'160px', paddingTop:32}}>
            <img src={althar} style={{width:'100%', height:'100%', objectFit:'contain'}} />
          </div>
        </Grid>
        <Grid item>
        <svg  x="0px" y="0px" opacity={1}
          width='625px' height='197px' className={`svg-icon ${className || ""}`}
          >
            <g>
              <g>
                <g>
                  {/* <path fill="#D64189" d="M95.668,48.177l40.381,121.458v19.125h-30.927l-7.286-22.674H42.286l-7.289,22.674H4.073v-19.125
                    L44.454,48.177H95.668z M54.696,5.397h30.926l16.549,22.474v7.688H85.424L70.059,17.03L55.09,35.559H38.15v-7.688L54.696,5.397z
                    M49.18,141.043h41.957L70.059,78.151L49.18,141.043z"/> */}
                  
                  <path fill="#B84385" d="M155.143,42.267h31.318V188.76h-31.318V42.267z"/>
                  <path fill="#B84385" d="M202.612,87.616h13.985V65.927h31.319v21.688h22.457v23.655h-22.457v40.028
                    c0,8.279,4.925,13.801,12.607,13.801c3.152,0,7.289-0.396,10.637-1.383v25.834c-6.105,1.967-14.381,3.154-21.274,3.154
                    c-19.894,0-33.289-14.986-33.289-37.662v-43.772h-13.985V87.616z"/>
                  <path fill="#B84385" d="M389.147,123.889v64.871h-31.323v-55.996c0.198-12.618-7.682-21.493-18.712-21.493
                    c-10.836,0-18.518,8.681-18.518,20.903v56.585h-31.32V42.267h31.32v55.995c6.896-9.271,17.531-14.591,29.745-14.591
                    C373.188,83.671,389.341,100.235,389.147,123.889z"/>
                  <path fill="#B84385" d="M520.518,138.286v50.474H491.76v-12.027c-8.271,10.25-20.289,15.973-34.271,15.973
                    c-29.353,0-49.642-22.279-49.642-54.419c0-31.744,23.241-54.614,56.138-54.614C496.88,83.671,520.518,106.542,520.518,138.286z
                    M439.167,138.286c0,15.578,10.439,26.814,25.019,26.814c14.573,0,25.013-11.236,25.013-26.814
                    c0-15.771-10.439-27.015-25.013-27.015C449.606,111.271,439.167,122.514,439.167,138.286z"/>
                  <path fill="#B84385" d="M620.38,91.555v28.396h-15.364c-1.777-5.126-7.487-8.681-13.593-8.681
                    c-10.05,0-17.142,9.86-17.142,24.056v53.433h-31.32V87.616H573.1v13.009c5.71-10.251,15.56-16.759,26.991-16.759
                    C608.164,83.866,616.436,87.02,620.38,91.555z"/>
                </g>
              </g>
            </g>
          </svg>
        </Grid>
      </Grid>
    
    )
  }