import React, { useRef, useState, useEffect } from 'react'
import { Slide, withStyles } from '@material-ui/core';
import ScreenA from './components/ScreenA';
import CircleLogo from './components/CircleLogo';
import ScreenB from './components/ScreenB';
import ScreenC from './components/ScreenC';
import useSectionC from './useSectionC';
import logo from '../../../../assets/logo_althar.png'


const totalHeight = 4500

const range_first_screen = {min:0, max:1200, offset:0}
const range_second_screen = {min:0, max:1200, offset:1200}
const range_third_screen = {min:0, max:1200, offset:2400}

const range_fourth_logo = {min:0, max:400, offset:3400}

const range_logo = {min:0, max:3000, offset:0}

const SectionC = ({classes, language, scroll_position, offset, onChangeValueModal}) => {

  const {actions} = useSectionC({scroll_position, offset})
  
  let fade_first_screen = actions.onFadeText(range_first_screen)
  let fade_second_screen = actions.onFadeText(range_second_screen)
  let fade_third_screen = actions.onFadeText(range_third_screen)
  let scale_logo = actions.onScaleSVG(range_logo)

  let myContainer = useRef()

  //console.log('setionD')

  return( 
    <div className={classes.root} ref={(ref) => {myContainer = ref}}> 
      <div className={classes.emptySpace} />
      <div className={classes.container} style={{overflow:'hidden', }}>
        <div className={classes.subcontainer} style={{
          overflow:'hidden'
          }}>
            <ScreenA language={language} scroll={scroll_position} range={range_first_screen} offset={offset} fade_in={fade_first_screen}/>
            <ScreenB language={language} scroll={scroll_position} range={range_second_screen} offset={offset} fade_in={fade_second_screen}/>
            <ScreenC onChangeValueModal={onChangeValueModal} language={language} scroll={scroll_position} range={range_third_screen} offset={offset} fade_in={fade_third_screen}/>
            <CircleLogo 
              max_visibility={9999999999999999999999}
              scroll={scroll_position} 
              g_offset={offset}
              range={range_first_screen}  
              scale={scale_logo}/>      
        </div>
        <Slide direction='right' in={scroll_position > range_fourth_logo.min + range_fourth_logo.offset + offset}  timeout={1200}>
          <div className={classes.logo_container}>
              <img src={logo} alt='' className={classes.logo}/>
          </div>
        </Slide>
      </div>
    </div>
  )
  
}



const styles = theme => ({
  generalContainer:{
    width:1400,
    margin:'auto',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
  },
  root:{
    height:`${totalHeight}px`,
    position:'relative',
    background:'black'
  },
  rootB:{
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%)',
  },
  rootC:{
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%)',
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing'
  },
  container:{
    width:'100%',
    //height:'100vh',
    height:'140vh',
    position:'sticky',
    top:'0px',
    [theme.breakpoints.only('xs')]: {
      height:'100vh'
    },
  },
  subcontainer:{
    
  },
  containerB:{
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%)',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 2500,
    }),
  },
  imageContainer:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    background:'transparent',

  },
  image:{
    width:'100%',
  },
  letter:{
    fontFamily:'Avenir',
    fontSize:140,
    fontWeight:600,
    padding:0,
    margin:0,
  },
  txtWrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-70%, -50%)',
    zIndex:1
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:1
  },
  textB_container:{ 
    width:1200,
    fontFamily:'Avenir',
    fontSize:120,
    fontWeight:600,
  },
  overlay:{
     
    width:'100%', 
    height:'100%',
    top:0,
    left:0,
    position:'absolute',
    zIndex:1
  },
  screenWrapperA:{
    overflowX:'hidden', 
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%);',
  },
  logo:{
    width:700
  },
  logo_container:{
    position:'absolute',
    bottom:-12,
    left:100,
    zIndex:1,
    //background:'red',
    //width:'100%'
    [theme.breakpoints.down('sm')]: {
      display:'none'
    },
  }
})

export default withStyles(styles)(SectionC)