import React, {useState} from 'react'
import { makeStyles, Grid, Slide, Icon, Grow, Fab, useMediaQuery, useTheme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import cx from 'classnames'
import digitalTerapeutics from '../../assets/circleMenu/digitalTerapeutics.jpg'
import electronicMedicalRecords from '../../assets/circleMenu/electronicMedicalRecords.jpg'
import experientialEventStandAlone from '../../assets/circleMenu/experientialEventStandAlone.jpg'
import glucometer from '../../assets/circleMenu/glucometer.jpg'
import lmsELearning from '../../assets/circleMenu/lmsELearning.jpg'
import servelessComputing from '../../assets/circleMenu/serverlessComputing.jpg'
import telemedicine from '../../assets/circleMenu/telemedicine.jpg'
import telepharmacy from '../../assets/circleMenu/telepharmacy.jpg'
import vrOncologyManagement from '../../assets/circleMenu/vrOncologyManagement.jpg'

const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:1,
    width:'100%',
    height:'100vh',
  },
  container:{
    width:1400,
    margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('xl')]: {
      width:1400,
    },
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 50px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 30px'
    },
  },
  subcontainer:{
    position:'absolute',
    marginTop:100,
    [theme.breakpoints.only('sm')]: {
      position:'initial'
    },
    [theme.breakpoints.only('xs')]: {
      position:'initial'
    },
  },
  title:{
    fontSize:48,
    fontWeight:800,
    '& strong':{
      color:theme.palette.primary.main,
      fontWeight:800
    },
    [theme.breakpoints.only('lg')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:36,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:30,
    },
  },
  subtitle:{
    marginTop:100,
    fontSize:24,
    fontWeight:500,
    width:900,
    [theme.breakpoints.only('lg')]: {
      width:800,
      fontSize:20,
    },
    [theme.breakpoints.only('md')]: {
      width:540,
      fontSize:18,
    },
  },
  menu:{
    width:700,
    //background:'red',
    [theme.breakpoints.only('xs')]: {
      width:'initial',
    },
  }
}))

const ScreenC = props => {

  const classes = useStyles()
  const {scroll, range, language, offset, onChangeValueModal} = props

  const content = contentData[language]

  return(
    <div className={classes.root}>
      <div className={classes.container}>
        
          <div className={classes.subcontainer}>
            <Slide direction='right' in={scroll > range.min + range.offset + offset} timeout={1200}>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.title} style={{color:fade('#FFF', 1)}}>
                      {content.title} 
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.menu}>
                      <Grid container spacing={0}>
                        
                        {content.menuItems.map((item,key)=>{
                          return(
                            <Grid item xs={6} sm={4} md={4} key={key.toString()}>
                              <Grid container>
                                <Grid item>
                                    <CircleModalItem  onChangeValueModal={onChangeValueModal} title={item.label} image={item.img} blue={key%2 !== 0}/>
                                </Grid>
                              </Grid>
                              
                            </Grid>
                          )
                        })}
                        
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Slide>
          </div>
        
      </div>
    </div>
  )
}

export default ScreenC

const contentData = {
  english:{
    title:'Just a sample of our work:',
    menuItems:[
      {id:1, label:'Glucometer', img: glucometer},
      {id:2, label:'Digital Therapeutics', img: digitalTerapeutics},
      {id:3, label:'Telemedicine', img: telemedicine},
      {id:4, label:'Telepharmacy', img: telepharmacy},
      {id:5, label:'Electronic Medical Records', img: electronicMedicalRecords},
      {id:6, label:'Serverless Computing', img: servelessComputing},
      {id:7, label:'LMS & E-learning', img: lmsELearning},
      {id:8, label:'VR for Oncology Managment', img: vrOncologyManagement},
      {id:9, label:'Experiential Event Stand Alone', img: experientialEventStandAlone},
    ]
  }
}


const useStyles2 = makeStyles(theme => ({
  root:{
    background:theme.palette.primary.main,
    width:200,
    margin:32,
    height:200,
    borderRadius:'100%',
    position:'relative',
    cursor:'pointer',
    [theme.breakpoints.only('sm')]: {
      width:180,
      height:180,
      marginBottom:-15,
      marginLeft:0,
    },
    [theme.breakpoints.only('xs')]: {
      width:120,
      height:60,
      borderRadius:10,
      marginBottom:-20,
      marginLeft:0,
      //background:'red'
    },
  },
  text:{
    position:'absolute',
    textAlign:'center',
    width:150,
    fontWeight:600,
    fontSize:24,
    top:'50%',
    left:'50%',
    transform:'translate(-50%, -50%)',
    [theme.breakpoints.only('xs')]: {
      width:120,
      fontSize:16,
    },
  },
  circle:{
    zIndex:-1,
    position:'absolute',
    background:theme.palette.primary.main,
    top:100,
    right:100,
    width:'265vw',
    height:'265vw',
    transform: 'translate(50%, -50%) scale(0.0017)',
    borderRadius:'100%',
    transition:'transform 0.5s ease 0s',
    [theme.breakpoints.only('sm')]: {
      transform: 'translate(51%, -50%) scale(0.02)'      
    },
    [theme.breakpoints.only('xs')]: {
      transform: 'translate(55%, -58%) scale(0.02)',
      display:'none'
    }
  },
  circleOpenBlue:{
    background:'#01A1FF',
    transition:'background 0.5s ease 0s', 
    zIndex:1,
    transform: 'translate(50%, -50%) scale(1)',
    transition:'transform 0.5s ease 0s',
    [theme.breakpoints.only('xs')]: {
      display:'initial'
    }
  },
  circleOpen:{
    zIndex:1,
    transform: 'translate(50%, -50%) scale(1)',
    transition:'transform 0.5s ease 0s',
    [theme.breakpoints.only('xs')]: {
      display:'initial'
    }
  },
  imageContainer:{
    position:'relative',
    width:'100%',
    height:'100vh',
    background:'red',
    backgroundSize:'cover',
    backgroundPosition:'center',
    [theme.breakpoints.only('xs')]: {
      //height:'auto',
      width:'100%',
      height:'100%',
      backgroundSize:'contain'
    }
  },
  image:{
    position:'absolute',
    width:'100%',
    top:'30%',
    //left:'50%',
    transform:'translate(-50%,0%)'
  },
  btnClose:{
    top:30,
    right:30,
    position:'fixed',
    zIndex:9999999999
  },
  nav:{
    
    position:'fixed',
    top:0,
    left:0,
    width:'100%',
    height:'100vh',
    //height:'100%',
    zIndex:10000,
    transform:'scale(0)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeOut,
      duration: 200,
    }),
  },
  navOpen:{
    zIndex:2,
    transform:'scale(1)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easiIn,
      duration: 200,
    }),
  },
}))

const CircleModalItem = props => {

  const [open, setValue] = useState(false)
  const classes = useStyles2()
  const {title, image, blue} = props

  const theme = useTheme()
  const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onChange = () => {
    setValue(!open)
    //props.onChangeValueModal(!open)
    props.onChangeValueModal('topic', !open)
  }

  return(
    <div className={classes.root} onClick={onChange}>
      <nav className={cx({
        [classes.nav]:true,
        [classes.navOpen]:open
      })}>
        <Grow in={open} timeout={2000}>
        {!ismobile ? <div className={classes.imageContainer} style={{
            backgroundImage:`url(${image})`}}>
          
        </div> :
        <img 
            src={image} 
            className={cx({
              [classes.image]:true
            })}
          />}
        </Grow>
      </nav>
      <div className={classes.text}>
        {title} 
      </div>
      {open ? (
        <Fab className={classes.btnClose}><Icon>close</Icon></Fab>
      ) : null}
      <div className={cx({
        [classes.circle]:true,
        [classes.circleOpen]:open,
        [classes.circleOpenBlue]:open&&blue,
      })}></div>
    </div>
  )
    
}