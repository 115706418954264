import { useEffect, useState } from "react"


const useSectionC = ({scroll_position, offset}) => {

    const actions = {
        onFadeText: (range) => {
            let fading = 1
            if(scroll_position >= (range.min + range.offset + offset) && scroll_position <= (range.max + range.offset + offset)){

            fading -= (scroll_position-range.offset - offset)/(range.max-range.min)
            
            if(fading < 0){
                fading = 0
            }
            }else{
            fading = 0
            }
            return fading
        },        
        onScaleSVG: (range) => {
            let size = 0
            if(scroll_position >= (range.min + range.offset + offset) && scroll_position <= (range.max + range.offset +offset)){

            size += (scroll_position-range.offset - offset)/(range.max-range.min)*0.75
            
            if(size < 0){
                size = 0
            }
            if(size > 0.5){
                size = 0.5
            }
            }else{
            if(scroll_position > (range.max + range.offset +offset))
            size = 0.5
            }
            //console.log(size)
            return size
        },
        onItemValid: (range, type) => {
            return scroll_position >= range.min+range.offset  && scroll_position < range.max+range.offset
        }
    }

    
    return {actions}
}

export default useSectionC