import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import cx from 'classnames'
import PublicRouters from '../../routes/router';
import NavBar from './components/NavBar';
import BlockPage from './components/BlockPage';


const WebsiteLayout = ({blur, history, language, banner}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    //const [topic_modal, setTopicModal] = useState(false)
    //const onChangeModal = (value) => setTopicModal(value)

    const [modals, setModals] = useState({
        topic:false,
        bubble_image:false
    })

    const [block, setBlock] = useState(true)

    useEffect(() => {
        const enablePlatform = localStorage.getItem('enablePlatform')
        const passPlatform = localStorage.getItem('credentials')
        if(enablePlatform === 'active' && passPlatform === 'CORPAlthar2022.1'){
            setBlock(false)
        }
    }, [])


    const onChangeModalStatus = (key, status) => {
        let _modals = {...modals}
        _modals[key] = status
        setModals(_modals)
    }
   
    return ( 
        <div>                  
            {!modals.topic && !modals.bubble_image ? <NavBar history={history}  /> : null}
            <BlockPage open={block} onClose={() => setBlock(false)} />
            <PublicRouters history={history} language={language} banner={banner} 
                modals={modals} onChangeModal={onChangeModalStatus} />                   
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:'#FBFBFB',
        minHeight:'100vh',
        overflowY:'auto',
        filter:'none'

    },
    root_blur:{
        filter:'blur(3px)'
    },
    main:{
        //marginLeft:150,
        //padding:16,
        overflowY:'auto',
        //background:'red'
        
    },
    /* subroot:{
        marginTop:72,
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: DASHBOARD_SETTINGS.SIDEBAR.WIDTH,
        [theme.breakpoints.down('sm')]: {
            marginLeft:0
          },
    },
    subroot_shift:{
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        
    }, */
}))


export default WebsiteLayout;

