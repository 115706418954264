import React, { useEffect } from 'react'
import {Button, Grid, Icon, makeStyles, Slide, Typography, Zoom } from '@material-ui/core'
import logo from '../../assets/logo-althar-blanco.png'
import prueba from '../../assets/punch.png'



const WorkView = ({language}) =>{

  const classes = useStyles()

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return(
    <div className={classes.root}>  
      <Slide direction='right' in={true}  timeout={1200}>
        <div className={classes.title_container}>
            <img src={logo} alt='' className={classes.logo}/>
            <Typography variant='h2' className={classes.title} >WORK</Typography>
        </div>
      </Slide> 
      <Zoom in timeout={2000}>
        <Typography style={{marginTop:12}} variant='h3'>Our work</Typography>
      </Zoom>
      <div className={classes.video_container}>
        <div className={classes.container}></div>
      </div>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  root:{
    position:'relative',
    padding:'100px 40px',
    background:theme.palette.primary.main,
    height:'100vh'
  },
  title:{
    //color:theme.palette.primary.main,
    marginLeft:24
  },
  title_container:{
    position:'relative',
    display:'flex',
    alignItems:'end'
  },
  video_container:{
    //marginLeft:40,
    marginTop:100,
    width:'100%',
  },
  container:{
    width:1000,
    height:350,
    background:'white',
    margin:'auto'
  },
  logo:{
    width:400,
    paddingBottom:12
  },
  
}))

const contentData = {
  english:{
    sectionA:{
      title:'Mission',
      data:'We are transforming health with technology, data, design and frenzy for life.',
      timeout:2400
    },
    sectionB:{
      title:'Courage',
      data:'Enthusiasm, true conviction and fearless believe lead our standards way beyond expectations.',
      timeout:3000
    },
    sectionC:{
      title:'Character',
      data:'Mixing our talents and moral quality; We have the right motives to achieve greater results.',
      timeout:3600
    },
    sectionD:{
      title:'Integrity',
      data:'We always do the right things, building up strong and lasting working relationships.',
      timeout:4200
    },
    sectionE:{
      title:'Innovation',
      data:'We strive toward to create and design.',
      timeout:4800
    }
  }
}

export default WorkView