import React from 'react'
import { makeStyles } from '@material-ui/core';
import banner from '../assets/banner.jpg'


const useStyles = makeStyles(theme => ({
  root:{
    width:'100%',
    height:'100vh',
    backgroundImage: `url(${banner})`,
    backgroundSize:'cover',

  }
}))

const Banner = props => {

  const classes = useStyles()

  return(
    <div className={classes.root} >
    </div>
  )
}

export default Banner