import { useEffect, useState } from "react"


const useOurClientsView = ({}) => {

   const [scroll_position, setScrollPosition] = useState(0)

    const [modals, setModals] = useState({
        internal_modal:false
    })
  
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


    const actions = {
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        listenToScroll: () => {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop
            const scrolled = winScroll
            setScrollPosition(scrolled)
        },
        //for sections
        onFadeText: (range) => {
            let fading = 1
            if(scroll_position >= (range.min + range.offset) && scroll_position <= (range.max + range.offset)){
        
              fading -= (scroll_position-range.offset)/(range.max-range.min)
              
              if(fading < 0){
                fading = 0
              }
            }else{
              fading = 0
            }
            return fading
        },        
        onScaleSVG: (range) => {
            let size = 1
            if(scroll_position >= (range.min + range.offset) && scroll_position <= (range.max + range.offset)){
        
                size += (scroll_position-range.offset)/(range.max-range.min)*4
                
                if(size < 0){
                size = 0
                }
            }else{
                if(scroll_position > (range.max + range.offset)){
                size += (scroll_position-range.offset)/(range.max-range.min)*4
                
                }
            }
            return size
        },
        onChangeIconWidth: (range) => {
            let size = 1200
            if(scroll_position >= (range.min + range.offset) && scroll_position <= (range.max + range.offset)){
        
                size += (scroll_position-range.offset)*1.5
                
                if(size < 0){
                size = 0
                }
            }
            return size
        },
        onItemValid: (range, type) => {
            return scroll_position >= range.min+range.offset  && scroll_position < range.max+range.offset
        }
    }

    
    return {modals, scroll_position, actions}
}

export default useOurClientsView