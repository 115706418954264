import React, { useState } from 'react'
import { Fab, Grid, Icon, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames'
import afforday from '../../../../../assets/afforday.jpg'
import dexipo from '../../../../../assets/dexipo.jpg'
import galenox from '../../../../../assets/galenox.jpg'
import preenarm from '../../../../../assets/preenarm.jpg'
import { grey } from '@material-ui/core/colors';

//semi bubbles
import prueba from '../../../../../assets/carouselD.jpg'
/* import sb_afforday from '../../../../../assets/PNG/PNG/WEBnegro_55.png'
import sb_dexipo from '../../../../../assets/PNG/PNG/WEBnegro_57.png'
import sb_galenox from '../../../../../assets/PNG/PNG/WEBnegro_59.png'
import sb_preenarm from '../../../../../assets/PNG/PNG/WEBnegro_61.png' */

//images with backghround
import sb_afforday from '../../../../../assets/WEB_55.png'
import sb_dexipo from '../../../../../assets/WEB_57.png'
import sb_galenox from '../../../../../assets/WEB_59.png'
import sb_preenarm from '../../../../../assets/WEB_61.png'


const imageCluster = [ afforday, dexipo, galenox, preenarm ]

/* const imageBubble = [ 
  {img:sb_afforday, background:'#00A0FF', title:'Afforday', text:'Is a SaaS cloud AI-based affordability tool for patient support programs or affordability programs.'}, 
  {img:sb_dexipo, background:'#D64189', title:'Dexipo', text:'AI cloud-based KOLs management system.'}, 
  {img:sb_galenox, background:'#00A0FF', title:'Galenox', text:'A medical content PWA platform.'}, 
  {img:sb_preenarm, background:'#D64189', title:'Pre-ENARM', text:'ENARM LMS with more than 1,400 e-learning courses.'}] */

const imageBubble = [ sb_afforday, sb_dexipo, sb_galenox, sb_preenarm ]

const ScreenA = ({language, classes, onChangeModal}) => {

  const [selected, setSelected] = useState(1)
  const content = contentData[language]
  const selectedImage = imageCluster[selected-1]

  const [open, setOpen] = useState(false)

  const onChangeItem = (id) => {
    setSelected(id)
  }

  const onCloseFab = () => {
    setOpen(false)
    //props.onChangeValueModal(!open)
    onChangeModal('bubble_image', false)
  }

  const onSelectItem = () => {
    setOpen(true)
    onChangeModal('bubble_image', true)
  }



  return(
    <div className={classes.root} style={{backgroundImage:`url(${selectedImage})`}} >
        {open ? (
          <Fab className={classes.btnClose} onClick={onCloseFab} ><Icon>close</Icon></Fab>
        ) : null}
        <div className={cx(({
            [classes.semi_bubble]:true,
            [classes.bubble]:open
          }))}  >
          {/* <div className={cx(({
              [classes.bubble_text]:true,
              [classes.bubble_text1]:selected === 1,
              [classes.bubble_text2]:selected === 2,
              [classes.bubble_text3]:selected === 3,
              [classes.bubble_text4]:selected === 4,
            }))}>
            <Grid container alignItems='center' spacing={5} wrap='nowrap'>
              <Grid item><Typography style={{fontWeight:900, fontSize:'2.5rem'}} >{imageBubble[selected-1].title}</Typography></Grid>
              <Grid item><Typography style={{fontSize:'2.5rem'}} >{imageBubble[selected-1].text}</Typography></Grid>
            </Grid>
          </div> */}
          <img src={imageBubble[selected-1]} className={cx(({
            [classes.image_semi_bubble]:true, [classes.image_bubble]: open}))} 
          alt='' />
        </div>
        <div className={classes.container}>
          
            <div className={classes.subcontainer}>
              <Grid container spacing={6}>
                {content.menu.map((item,key)=>{
                  return(
                    <Grid item xs={12}>
                      <DataWrapper data={item} selected={selected} onSelected={onChangeItem} onClick={onSelectItem} />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
        </div>
      
    </div>
  )
  
}

const styles = theme => ({
  root:{
    position:'absolute',
    zIndex:10,
    width:'100%',
    height:'100vh',
    backgroundSize:'cover',
    backgroundPosition:'center',
    transition: theme.transitions.create(['background-image'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  container:{
    width:1400,
    margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    overFlow:'hidden',
    //background:'red',
    [theme.breakpoints.only('xl')]: {
      width:1400,
    },
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 50px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 16px'
    },
  },
  subcontainer:{
    position:'absolute',
    top:'50%',
    transform:'translateY(-50%)'

  },
  title:{
    fontSize:64,
    fontWeight:800,
    '& strong':{
      color:theme.palette.primary.main,
      fontWeight:800
    },
    [theme.breakpoints.only('lg')]: {
      fontSize:48,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:36,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:30,
    },
  },
  subtitle:{
    marginTop:100,
    fontSize:24,
    fontWeight:500,
    width:800,
    [theme.breakpoints.only('lg')]: {
      width:660,
      fontSize:20,
    },
    [theme.breakpoints.only('md')]: {
      width:540,
      fontSize:18,
    },
    [theme.breakpoints.only('sm')]: {
      width:540,
      fontSize:16,
    },
    [theme.breakpoints.only('xs')]: {
      width:540,
      fontSize:14,
    },
  },
  semi_bubble:{
    position:'absolute',
    //borderRadius:'50%',
    width:1300,
    height:1300,
    //top:'0px',
    bottom:'-18%',
    right:'-50%',
    //background:'red',
    //transform:'scale(0.8)',
    //right:10,
    //transition:'transform 0.5s ease 0.5s',
    [theme.breakpoints.down('lg')]: {
      width:1200,
      height:1200,
      //right:'-60%'
      //top:-70
    },
    [theme.breakpoints.down('md')]: {
      right:'-70%'
      //top:-70
    },
    [theme.breakpoints.down('sm')]: {
      display:'none'
    },
  },
  bubble:{
    //transform: 'scale(10)',
    width:'100vw',
    height:'100vh',
    top:0,
    right:0,
    //left:0,
    borderRadius:0,
    objetFit:'cover',
    //transform:'scale(1)',
    //transition:'all 0.5s ease',
    transition:'right 1s ease',
    zIndex:20,
    background:'transparent',
    [theme.breakpoints.down('sm')]: {
      display:'initial'
    },
  },
  image_semi_bubble:{
    width:'100%',
    height:'100%',
    objectFit:'cover',
    borderRadius:'50%',
    //transition:'all 0.5s ease',
    //transition:'transform 0.5s ease 500ms'
    //overFlow:'hidden'
  },
  image_bubble:{
    width:'100%',
    height:'100%',
    objetFit:'cover',
    borderRadius:0,
    transition:'all 2.5s ease',
    [theme.breakpoints.down('sm')]: {
      objectFit:'contain'
    },
    
  },
  btnClose:{
    top:30,
    right:30,
    position:'fixed',
    zIndex:1000
  },
  bubble_text:{
    color:'white',
    position:'absolute'
  },
  bubble_text1:{
    //margin:100
    left:100,
    bottom:50,
    width:1200
  },
  bubble_text2:{
    //margin:100
    top:100,
    left:100,
  },
  bubble_text3:{
    //margin:100
    left:100,
    bottom:50,
    //width:1200
  },
  bubble_text4:{
    //margin:100
    top:100,
    left:100,
    width:1200
  }


})

export default withStyles(styles)(ScreenA)

const contentData = {
  english:{
    menu:[
      {
        id:1, 
        title:'Afforday',
        content:'Is a SaaS cloud AI-based affordability tool for patient support programs or affordability programs.'
      },
      {
        id:2, 
        title:'Dexipo',
        content:'AI cloud-based KOL management system.'
      },
      {
        id:3, 
        title:'Galenox',
        content:'A medical content PWA platform.'
      },
      {
        id:4, 
        title:'Pre-ENARM',
        content:'ENARM LMS with more than 1,400 e-learning courses.'
      }
    ]
  }
}

const useStyles = makeStyles(theme => ({
  root:{
    color:grey[500],
    '& :hover':{
      cursor:'pointer'
    },
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.easeIn,
      duration: 350,
    }),
  },
  rootSelected:{
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.easeIn,
      duration: 350,
    }),
  },
  container:{
    [theme.breakpoints.only('sm')]: {
      display:'table-cell',
    },
    [theme.breakpoints.only('xs')]: {
      display:'table-cell',
    },
  },
  contentGrid:{
    [theme.breakpoints.only('sm')]: {
      padding:'0px 32px !important',
    },
    [theme.breakpoints.only('xs')]: {
      padding:'0px 16px !important',
    },
  },
  title:{
    fontWeight:700,
    fontSize:48,
    [theme.breakpoints.only('lg')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:36,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:32,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:28,
    },
  },
  content:{
    marginTop:8,
    fontWeight:500,
    fontSize:36,
    [theme.breakpoints.only('lg')]: {
      fontSize:28,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:24,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:20,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:16,
    },
  },
  
}))

const DataWrapper = props => {

  const {data, onSelected, selected, onClick} = props
  const classes = useStyles()

  return(
    <div className={cx({
      [classes.root]:true,
      [classes.rootSelected]:selected === data.id
    })} onMouseOver={() => onSelected(data.id)} onClick={onClick} >
      
      <Grid container spacing={8} wrap='nowrap' className={classes.container}>
        <Grid item className={classes.contentGrid}>
          <div className={classes.title}>{data.title}</div>
        </Grid>
        <Grid item className={classes.contentGrid}>
          <div className={classes.content}>{data.content}</div>
        </Grid>
      </Grid>
    </div>
  )
}