import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  svgImage:{
    position:'absolute',
    width:'625px',//'625px',
    height:'197px',//'197px',
    top:'50%',
    left:'50%',
    zIndex:1,
    transition: theme.transitions.create(['transform','width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 1000,
    }),
    [theme.breakpoints.down('sm')]: {
      transform:'translate(0%, 0%) scale(0.85)',
    },
    [theme.breakpoints.down('xs')]: {
      transform:'translate(-30%, 130%) scale(0.4)',
    },
  },
}))

const AltharLogo = props => {
  const classes = useStyles()
  
    return(
      <div className={classes.svgImage}>
        <svg  x="0px" y="0px" opacity={props.opacity !== null && props.opacity !== undefined ? props.opacity : 1}
            width='625px' height='197px'
            >
              <g>
                <g>
                  <g>
                    <path fill={props.color ? props.color : "#D64189"} d="M95.668,48.177l40.381,121.458v19.125h-30.927l-7.286-22.674H42.286l-7.289,22.674H4.073v-19.125
                      L44.454,48.177H95.668z M54.696,5.397h30.926l16.549,22.474v7.688H85.424L70.059,17.03L55.09,35.559H38.15v-7.688L54.696,5.397z
                      M49.18,141.043h41.957L70.059,78.151L49.18,141.043z"/>
                    <path fill={props.color ? props.color : "#D64189"} d="M155.143,42.267h31.318V188.76h-31.318V42.267z"/>
                    <path fill={props.color ? props.color : "#D64189"} d="M202.612,87.616h13.985V65.927h31.319v21.688h22.457v23.655h-22.457v40.028
                      c0,8.279,4.925,13.801,12.607,13.801c3.152,0,7.289-0.396,10.637-1.383v25.834c-6.105,1.967-14.381,3.154-21.274,3.154
                      c-19.894,0-33.289-14.986-33.289-37.662v-43.772h-13.985V87.616z"/>
                    <path fill={props.color ? props.color : "#D64189"} d="M389.147,123.889v64.871h-31.323v-55.996c0.198-12.618-7.682-21.493-18.712-21.493
                      c-10.836,0-18.518,8.681-18.518,20.903v56.585h-31.32V42.267h31.32v55.995c6.896-9.271,17.531-14.591,29.745-14.591
                      C373.188,83.671,389.341,100.235,389.147,123.889z"/>
                    <path fill={props.color ? props.color : "#D64189"} d="M520.518,138.286v50.474H491.76v-12.027c-8.271,10.25-20.289,15.973-34.271,15.973
                      c-29.353,0-49.642-22.279-49.642-54.419c0-31.744,23.241-54.614,56.138-54.614C496.88,83.671,520.518,106.542,520.518,138.286z
                      M439.167,138.286c0,15.578,10.439,26.814,25.019,26.814c14.573,0,25.013-11.236,25.013-26.814
                      c0-15.771-10.439-27.015-25.013-27.015C449.606,111.271,439.167,122.514,439.167,138.286z"/>
                    <path fill={props.color ? props.color : "#D64189"} d="M620.38,91.555v28.396h-15.364c-1.777-5.126-7.487-8.681-13.593-8.681
                      c-10.05,0-17.142,9.86-17.142,24.056v53.433h-31.32V87.616H573.1v13.009c5.71-10.251,15.56-16.759,26.991-16.759
                      C608.164,83.866,616.436,87.02,620.38,91.555z"/>
                  </g>
                </g>
              </g>
        </svg>
      </div>
   
    )
  }

  export default AltharLogo