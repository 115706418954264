import React from 'react'
import { makeStyles, Grid, Fade } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:1,
    width:'100%',
    height:'100vh',
  },
  container:{
    width:1400,
    margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 50px'
    },
  },
  subcontainer:{
    position:'absolute',
    top:'10%',
    //transform:'translateY(-50%)'
  },
  title:{
    fontSize:160,
    fontWeight:700,
  },
  subtitle:{
    fontSize:36,
    fontWeight:500,
    [theme.breakpoints.only('lg')]: {
      fontSize:32,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:26,
    },
    [theme.breakpoints.only('md')]: {
      width:340,
      fontSize:26,
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft:60,
      width:240,
      fontSize:20,
    },
  }
}))

const ScreenD = props => {

  const classes = useStyles()
  const {scroll, range, fade_in} = props

  return(
    <div className={classes.root}>
      <div className={classes.container}>
        
          <div className={classes.subcontainer}>
            <Fade in={scroll >= (range.min + range.offset)} timeout={3000}>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.subtitle} style={{color:fade('#FFF', fade_in)}}>We design solutions, services, products and technology for growth and transformation through a deep understanding of human behavior.</div>
                  </Grid>
                </Grid>
              </div>
            </Fade>
          </div>
        
      </div>
    </div>
  )
}

export default ScreenD