import React from 'react'
import { makeStyles, withWidth, Fade, Grid, Typography } from '@material-ui/core';
import logo from '../../../../../assets/althar_logo_g500.png'
import { grey } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:99,
    width:'100%',
    height:'100vh',
    marginTop:100
  },
  container:{
    width:'100%',
    margin:'150px auto',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    padding:'0px 32px',
    //background:'red',
    /* [theme.breakpoints.only('lg')]: {
      width:1100,
      margin:'100px auto',
    }, */
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px',
      margin:'80px auto',
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 32px',
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 32px',
      //marginTop:0,
      //height:260,
      //zIndex:200
      //background:'red',
    },
  },
  title:{
    fontSize:72,
    fontWeight:700,
    [theme.breakpoints.only('lg')]: {
      fontSize:48,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:38,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:32
    },
  },
  subtitle:{
    marginTop:32,
    fontSize:28,
    fontWeight:400,
    //width:'100%',
    paddingLeft:200,
    //paddingRight:16,
    color: theme.palette.primary.main,
    borderTop:'3px solid white',
    paddingTop:16,
    //background:'red',
    /* [theme.breakpoints.only('lg')]: {
      width:500,
      fontSize:24,
    }, */
    [theme.breakpoints.only('md')]: {
      fontSize:22,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:14,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft:0
    },
  },
  logo:{
    width:420,
    [theme.breakpoints.only('lg')]: {
      width:380,
    },
    [theme.breakpoints.only('md')]: {
      width:340,
    },
    [theme.breakpoints.only('sm')]: {
      width:300,
      marginLeft:'auto'
    },
    [theme.breakpoints.only('xs')]: {
      width:220,
    },
  },
  header:{
    //background:'red',
    [theme.breakpoints.only('sm')]: {
      marginTop:-120
    },
    [theme.breakpoints.only('xs')]: {
      marginTop:-130,
    },
  }
}))

const ScreenB = props => {

  const classes = useStyles()
  const {scroll, range, offset, content, color} = props

  const showContent = scroll >= range.min + range.offset + offset && scroll < range.max + range.offset + offset
  
  return(
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Fade in={showContent} timeout={2000}>
              <div className={classes.subtitle}>
                <Typography variant='h6'  >{content.p1}</Typography>
              </div>
            </Fade>
            <Grid item xs={12}>
              <Fade in={showContent} timeout={3000}>
                <div className={classes.subtitle}>
                  <Typography variant='h6' style={{color:'white'}} >{content.p2}</Typography>
                </div>
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withWidth()(ScreenB)