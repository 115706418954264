import React from 'react'
import { withStyles, fade, Typography, Fade, Slide, Zoom, Collapse } from '@material-ui/core';
import AltharLogo from './components/AltharLogo';
import ScreenB from './components/ScreenB'
import LogoGeneralMask from './components/LogoGeneralMask2';
import cx from 'classnames'
import ScreenD from './components/ScreenD';
import MeetLogo from './components/MeetLogo';
import dummy_image from '../../../../assets/collageAlthar.jpg'
import useSectionB from './useSectionB';

//new images
import image1 from '../../../../assets/PNG/PNG/WEBnegro_18.png'
import image2 from '../../../../assets/PNG/PNG/WEBnegro_19.png'
import image3 from '../../../../assets/PNG/PNG/WEBnegro_20.png'
import image4 from '../../../../assets/PNG/PNG/WEBnegro_21.png'
import image5 from '../../../../assets/PNG/PNG/WEBnegro_22.png'

import video from '../../../../assets/althar-credentials.mp4'
import Manifiesto from '../SectionA/components/Manifiesto';



const totalHeight = 8300
//const totalHeight = 6300

const range_first_screen = {min:0, max:1000, offset:6500, offset2:1000}
const range_second_screen = {min:0, max:1000, offset:7500}
//const range_overlay = {min:0, max:2000, offset:8500}
const range_overlay = {min:0, max:1600, offset:9000}

const SectionB = ({classes, language, scroll_position, actions}) => {

  const {width, height} = useSectionB({scroll_position})

  let fade_first_screen = actions.onFadeText(range_first_screen)
  let fade_second_screen = actions.onFadeText(range_second_screen)
  let scale_first_position = actions.onScaleSVG(range_first_screen)
  let overlay_fade = actions.onFadeText(range_overlay)
  let overlay_scale = actions.onScaleSVG(range_overlay)

  const max_visibility = range_second_screen.max + range_second_screen.offset
  console.log(video)

  return(
    <div className={cx({
      [classes.root]:true,
      [classes.rootB]: scroll_position > range_second_screen.offset + range_second_screen.max ,
    })} 
    //ref={(ref) => {this.myContainer = ref}}
    > 
      <div className={classes.emptySpace} />
      <div className={classes.container} style={{overflow:'hidden', }}>
        <div className={classes.subcontainer} style={{
          visibility:scroll_position < max_visibility + 1000 ? 'visible' : 'hidden',
          overflow:'hidden', 
          //background:'black',
          }}>
            <ScreenB language={language} scroll={scroll_position} range={range_first_screen} fade_in={fade_first_screen}/>
            <ScreenD language={language} scroll={scroll_position} range={range_second_screen} fade_in={fade_second_screen}/>
            {/*<div style={{position:'absolute', top:'50%', width:'100%', height:'1px', zIndex:90000, background:'white'}}/>*/}
            <AltharLogo 
              _width={width}
              _height={height}
              max_visibility={max_visibility}
              scroll={scroll_position} 
              range={range_first_screen}  
              scale={scale_first_position}/> 
            <MeetLogo 
              _width={width}
              _height={height}
              fade={fade_first_screen}
              max_visibility={max_visibility}
              scroll={scroll_position} 
              range={range_first_screen}  
              scale={scale_first_position}/> 
            
        </div>
        {/* {scroll_position > range_second_screen.offset + range_second_screen.max ? (
          <div className={classes.imageContainer} style={{visibility:'visible'}}>
            <div className={classes.overlay} style={{background:fade("#D64189",overlay_fade),}}/>
            <LogoGeneralMask scale={overlay_scale}/>
            <div className={classes.imageContainer}>
              <img src={dummy_image} alt='' className={classes.image}/>
            </div>
          </div>
        ) : null }  */}    
        {scroll_position > range_second_screen.offset + range_second_screen.max && scroll_position < range_second_screen.offset + range_second_screen.max + 600 ? (
          <div>
            <Fade in={scroll_position > range_second_screen.offset + range_second_screen.max} timeout={2600} >
              <div className={classes.description_cont} style={{visibility:'visible'}}>
                <div style={{maxWidth:900}} className={classes.description_text}>
                  <Typography variant='h6'  >
                    We are a company of professionals from different backgrounds like medicine,
                    human-centered design, engineering, innovation, hardware, software, digital,
                    business strategy, management, consulting, marketing, sales, luxury, arts and
                    research.
                  </Typography>
                </div>
                <div className={classes.rectangle_container} >
                  <div className={classes.pink_rectangle}></div>
                </div>
                
              </div>
            </Fade>
          </div>
        ) : null } 
        {scroll_position > range_second_screen.offset + range_second_screen.max + 550 && scroll_position < range_second_screen.offset + range_second_screen.max + 2100 ? (
          <div className={classes.description_cont} style={{visibility:'visible', background:'#D64189'}}>
            {/* <Slide in={scroll_position > range_second_screen.offset + range_second_screen.max + 650} direction='left' timeout={3000} ><Typography>Video reel</Typography></Slide>
            <div className={classes.pink_rectangle}></div> */}
            {scroll_position < 10064 ? <div className={classes.overlay} /> : null }
            {scroll_position > 10064 ?<video width="100%"  controls autoPlay style={{position:'relative', zIndex:10}}  >
                <source src={video} type="video/mp4" />
            </video> : null}
            <LogoGeneralMask scale={overlay_scale}/>
          </div>
        ) : null }
        {scroll_position > range_second_screen.offset + range_second_screen.max + 2050 && scroll_position < range_second_screen.offset + range_second_screen.max + 2500 ? (
          <div className={classes.imageContainer} style={{visibility:'visible', background:'white'}} >   
            <Fade  in={scroll_position > range_second_screen.offset + range_second_screen.max + 950} direction='right' timeout={2000} >
              <img src={image1} alt='' className={classes.image1}/> 
            </Fade >
          </div>
        ) : null }
        {scroll_position > range_second_screen.offset + range_second_screen.max + 2450 && scroll_position < range_second_screen.offset + range_second_screen.max + 3100 ? (
          <div className={classes.imageContainer} style={{visibility:'visible', background:'white'}} >   
            <Fade in={scroll_position > range_second_screen.offset + range_second_screen.max + 1550} timeout={3000} >
              <img src={image2} alt='' className={classes.image3}/> 
            </Fade>
          </div>
        ) : null }
        {scroll_position > range_second_screen.offset + range_second_screen.max + 3050 && scroll_position < range_second_screen.offset + range_second_screen.max + 4100 ? (
          <div className={classes.imageContainer} style={{visibility:'visible', background:'white'}} >   
            <Zoom in={scroll_position > range_second_screen.offset + range_second_screen.max + 2150} timeout={1500} >
              <img src={image3} alt='' className={classes.image3}/> 
            </Zoom>
          </div>
        ) : null }    
        {scroll_position > range_second_screen.offset + range_second_screen.max + 4050 && scroll_position < range_second_screen.offset + range_second_screen.max + 5100 ? (
          <div className={classes.imageContainer} style={{visibility:'visible', background:'white'}} >   
            <Slide in={scroll_position > range_second_screen.offset + range_second_screen.max + 3150} timeout={3000} direction='right' >
              <img src={image4} alt='' className={classes.image4}/> 
            </Slide>
          </div>
        ) : null }
        {scroll_position > range_second_screen.offset + range_second_screen.max + 5050  ? (
          <div className={classes.imageContainer} style={{visibility:'visible', background:'white'}} > 
            <Zoom in={scroll_position > range_second_screen.offset + range_second_screen.max + 4150} timeout={3000  } >
              <img src={image5} alt='' className={classes.image}/> 
            </Zoom>  
          </div>
        ) : null }
      </div> 
    </div>
  )
  
}



const styles = theme => ({
  generalContainer:{
    width:1400,
    margin:'auto',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
  },
  root:{
    height:`${totalHeight}px`,
    position:'relative',
    background:'black',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  rootB:{
    background:'black',
    color:'white',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  rootC:{
    background:'black',
    color:'white',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing'
  },
  container:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
  },
  subcontainer:{
    
  },
  containerB:{
    background:'black',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 2500,
    }),
  },
  imageContainer:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    //background:'transparent',
    overflow:'hidden',
    //background:'red'
  },
  image1:{
    width:'100%',
    height:'100%',
    background:theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      objectFit:'contain',
      height:'100%'
    },
  },
  image:{
    width:'100%',
    //height:'100%',
    //background:'red',
    [theme.breakpoints.down('md')]: {
      objectFit:'contain',
      height:'100%'
    },
    /* [theme.breakpoints.only('sm')]: {
      height:'100%',
      width:'auto'
    },
    [theme.breakpoints.only('xs')]: {
      height:'100%',
      width:'auto'
    }, */
  },
  image3:{
    width:'100%',
    transform:'rotate(-15deg)',
    [theme.breakpoints.only('md')]: {
      height:'100%',
      marginTop:100,
      objectFit:'cover'
      //width:'auto'
    },
    [theme.breakpoints.down('sm')]: {
      height:'100%',
      objectFit:'cover'
      //width:'auto'
    },
  },
  image4:{
    width:'100%',
    transform:'rotate(10deg)',
    marginLeft:400,
    marginTop:100,
    [theme.breakpoints.only('md')]: {
      height:'100%',
      width:'auto',
      marginLeft:0,
      marginTop:150,
    },
    /* [theme.breakpoints.only('sm')]: {
      height:'100%',
      width:'auto',
      marginLeft:0,
      marginTop:100,
    }, */
    [theme.breakpoints.down('sm')]: {
      height:'100%',
      width:'100%',
      marginLeft:0,
      marginTop:0,
      objectFit:'contain',
      //transform:'rotate(0deg)',
    },
  },
  letter:{
    fontFamily:'Avenir',
    fontSize:140,
    fontWeight:600,
    padding:0,
    margin:0,
  },
  txtWrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-70%, -50%)',
    zIndex:3
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:3
  },
  textB_container:{ 
    width:1200,
    fontFamily:'Avenir',
    fontSize:120,
    fontWeight:600,
  },
  overlay:{
    background:theme.palette.primary.main, 
    width:'100%', 
    height:'100%',
    top:0,
    left:0,
    position:'absolute',
    zIndex:1
  },
  screenWrapperA:{
    overflowX:'hidden', 
    background:'black',
  },
  description_cont:{
    background:theme.palette.secondary.main,
    //background:'transparent',
    position:'relative',
    //width:'100%',
    //width:'100vw',
    height:'100vh',
    padding:100,
    [theme.breakpoints.down('sm')]: {
      padding:32
    },
    //boxSizing:'border'
  },
  description_text:{
    [theme.breakpoints.down('sm')]: {
      marginTop:200
    },
  },
  rectangle_container:{
    /* width:'100%',
    position:'relative',
    display:'flex',
    justifyContent:'center',
    //alignItems:'center',
    marginTop:200,
    background:'red' */
  },
  pink_rectangle:{
    background:theme.palette.primary.main,
    width:'70%',
    height:300,
    margin:'200px auto 0px',
    [theme.breakpoints.down('sm')]: {
      height:200
    },
    [theme.breakpoints.down('xs')]: {
      height:100,
      marginTop:100
    },
  }
  
})

export default withStyles(styles)(SectionB)