import React from 'react'
import { Redirect } from 'react-router-dom'
import { Router, Route, Switch } from 'react-router-dom'
import MainView from '../views/MainView/MainView.jsx';
import Banner from '../views/Banner'
import UsView from '../views/UsView/UsView.jsx';
import ContactView from '../views/ContactView/ContactView.jsx';
import OurClientsView from '../views/OurClients/OurClientsView.jsx';
import NewslettersView from '../views/Newsletters/NewslettersView.jsx';
import WorkView from '../views/Work/WorkView.jsx';

const PublicRouters = (props) => {

  const {language, banner, modals, onChangeModal} = props
  //console.log(language)

  let content = <Route
    path={'/'}
    exact
    render={(props) => <MainView {...props} language={language} modals={modals} onChangeModal={onChangeModal} />} 
  />

  if(banner){
    content = <Route
      path={'/'}
      exact
      render={(props) => <Banner {...props} />} 
    />
  }

  return(
    <Router history={props.history}>
        <Switch>
          {content}
          <Route exact path='/work' render={(props) => <WorkView {...props} language={language} />} />
          <Route exact path='/newsletters' render={(props) => <NewslettersView {...props} language={language} />} />
          <Route exact path='/us' render={(props) => <UsView {...props} language={language} />} />
          <Route exact path='/our-clients' render={(props) => <OurClientsView {...props} language={language} />} />
          <Route exact path='/contact' render={(props) => <ContactView {...props} language={language} />} />
          <Redirect to={'/'}/>
        </Switch>
    </Router>
  );
}

export default PublicRouters
