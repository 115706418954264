import React, { useState, useEffect, useRef } from 'react'
import './stylestext.css'
import { withWidth } from '@material-ui/core';

const vector_size = {
  'xs': {width:1332,height:642, offset_x:0, offset_y:0, scale:0.5},
  'sm': {width:1332,height:642, offset_x:0, offset_y:0, scale:1},
  'md': {width:1332,height:642, offset_x:0, offset_y:0, scale:0.7},
  'lg': {width:1332,height:642, offset_x:0, offset_y:0, scale:0.85},
  'xl': {width:1332,height:642, offset_x:0, offset_y:0, scale:1},
}

const LogoGeneralMask = ({scale, width}) => {

  const [local_window, setWindow] = useState({
    width:0,
    height:0
  })

  let myContainer = useRef()

  useEffect(() => {
    let temp = {...local_window}
    const height = myContainer.clientHeight;
    const width = myContainer.clientWidth
    temp.height = height
    temp.width = width
    setWindow(temp)
  }, [])

  const updateDimensions = () => {
    let temp = {...local_window}
    const height = myContainer.clientHeight;
    const width = myContainer.clientWidth
    temp.height = height
    temp.width = width
    setWindow(temp);
  }

  const logo = vector_size[width]

  //const start_width = 1332
  //const start_height = 642

  const start_width = 1200
  const start_height = 700

  let current_scale = logo.scale - (1-scale)

  //const x_offset = local_window.width/2 - (start_width*current_scale)/2
  //const y_offset = local_window.height/2 - (start_height*current_scale)/2 + (scale-1)*250

  const x_offset = local_window.width/2 - (start_width*current_scale)/2
  const y_offset = local_window.height/2 - (start_height*current_scale)/2 

  //console.log(y_offset)

  return(
    <svg height="100%" width="100%" ref={(ref) => {myContainer = ref}}>
      <defs>
        <mask id="mask" x="0" y="0" height="100%" width="100%">
          <rect x="0" y="0" height="100%" width="100%" />
            <g transform={`translate(${x_offset},${y_offset})scale(${current_scale})`}>
              {/* <polygon points="983.75,12.242 350.791,12.242 12.104,472.203 12.104,629.551 354.839,629.551 669.294,250.319 
              975.653,629.551 1322.376,629.551 1322.376,472.203 "/>
              <polygon points="482.092,12.235 983.746,12.235 1322.372,472.205 1322.372,629.552 977.361,629.552 "/> */}
              <polygon fill="#D64189" points="250 180, 1000 180, 1000 500, 250 500" /> 
            </g>
        </mask>
      </defs>
      <rect x="0" y="0" height="100%" width="100%" style={{fill: 'white'}}/>
    </svg>
  )
  
}

export default withWidth()(LogoGeneralMask)