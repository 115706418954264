import { useEffect, useState } from "react"


const useSectionB = ({scroll_position}) => {

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const actions = {
        onFadeText: (range) => {
            let fading = 1
            if(scroll_position >= (range.min + range.offset) && scroll_position <= (range.max + range.offset)){

            fading -= (scroll_position-range.offset)/(range.max-range.min)
            
            if(fading < 0){
                fading = 0
            }
            }else{
            fading = 0
            }
            return fading
        },        
        onScaleSVG: (range) => {
            let size = 0
            if(scroll_position >= (range.min + range.offset) && scroll_position <= (range.max + range.offset)){

            size += (scroll_position-range.offset)/(range.max-range.min)*7.5
            
            if(size < 0){
                size = 0
            }
            }else{
            if(scroll_position > (range.max + range.offset)){
                size += (scroll_position-range.offset)/(range.max-range.min)*7.5
            
            }
            }
            return size
        },
        onChangeIconWidth: (range) => {
            let size = 1200
            if(scroll_position >= (range.min + range.offset) && scroll_position <= (range.max + range.offset)){

            size += (scroll_position-range.offset)*1.5
            
            if(size < 0){
                size = 0
            }
            }
            return size
        },
        onItemValid: (range, type) => {
            return scroll_position >= range.min+range.offset  && scroll_position < range.max+range.offset
        }
    }

    
    return {actions, width, height}
}

export default useSectionB