import React, { Component } from 'react';
import PublicRouters from './routes/router.jsx';
import { createBrowserHistory } from "history";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import WebsiteLayout from './layouts/WebsiteLayout/WebsiteLayout.jsx';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#D64189' }, // Purple and green play nicely together. #091635
    secondary: { main: 'rgba(52,33,107,1)' }, // This is just green.A700 as hex. #00acc1 '#4466f2'
    custom: {main: 'rgba(52,33,107,1)'} //2196F3
  },
  typography: {
    useNextVariants: true,
    fontFamily:[
      'Avenir',
      'Lato',
    ].join(','),
  },
  overrides:{
    MuiTypography:{
      h1:{
        fontSize:'7rem',
        fontWeight:900
      },
      h2:{
        fontSize:'4rem',
        fontWeight:900
      },
      h3:{
        fontSize:'3.5rem',
        fontWeight:900
      },
      h4:{
        fontWeight:900,
        fontSize:'2.5rem',
      },
      h5:{
          fontSize:'1.875rem',
          fontWeight:900
      },
      h6:{
        fontSize:'1.5rem',
        //fontWeight:600,
      },
      subtitle1:{
        fontSize:'1.25rem',
        fontWeight:900,
      },
      subtitle2:{
        fontSize:'1rem',
        fontWeight:700,
      },
      body1:{
          fontSize:'1rem',
          //fontWeight:500,
      },
      body2:{
          fontSize:'1rem',
          fontWeight:500,
      },
      caption:{
        fontSize:'0.875rem',
        fontWeight:500,
      },
      button:{
        fontSize:'1.125rem'
      },
    }
  }
});

const bannerOn = false

class App extends Component {
  render() {

    return (
      <MuiThemeProvider theme={theme}>
        
        <div>
          {/* <PublicRouters history={createBrowserHistory()} language={'english'} banner={bannerOn}/> */}
          <WebsiteLayout history={createBrowserHistory()} language={'english'} banner={bannerOn} />
        </div>
          
      </MuiThemeProvider>
    );
  }
}

export default App;
